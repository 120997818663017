    import api from "../../api/FdxCertificationPortalApi";
    import Toast from "../../components/Notifications/Toast"
    import router from '../../router/Router';
  

    const vueState = {
        showLoadingOnApiCall: false,
        userData: null,
        newApplicationData: null,
        applicationList: null,
        createdApplicationData: null,
        applicationDetails: null,
        dockerFileDetails: null,
        downloadFileURL: null,
        applicationsDetails: null,
        viewUploadedResults: null,
        activeUseCase: null,
        applicationListByFilter: null,
        applicationUserDetails: null,
        checklistSubmissionStatus: null,
        organizationDetailData: null,
        savedOrganizationDetailData: null,
        pointOfContactDetaildata:null,
        savedPointOfContactDetailData: null,
        apiAndProductStatus:null,
        savedApiAndProductDetailData: null, 
        combinedFormsDetails:null,
        applicationStatus: null,
        deletedCertification: null,
        fdxVersions: null,
        fdxRoles: null,
        certificationEnvironmentRootUrl: null,
    }

    const getters = {
        getFdxVersions(state) {
            return state.fdxVersions;
        },
        getFdxRoles(state) {
            return state.fdxRoles;
        },
        getCertificationAccountTypes(state) {
            return state.certificationEnvironmentRootUrl;
        },
    getNewApplicationData(state) {
        return state.newApplicationData;
    },
    getUserData(state) {
        return state.userData;
    },
    getApplicationListData(state) {
        return state.applicationList;
    },
    getCreatedApplicationData(state) {
        return state.createdApplicationData;
    },
    getApplicationDetails(state) {
        return state.applicationDetails;
    },
    getDockerFileDetails(state) {
        return state.dockerFileDetails;
    },
    getDownloadFileURL(state) {
        return state.downloadFileURL;
    },
    getViewUploadedResults(state) {
        return state.viewUploadedResults;
    },
    getActiveUsecase(state) {
        return state.activeUseCase;
    },
    getApplicationListByFilter(state) {
        return state.applicationListByFilter;
    },
    getApplicationUserDetails(state) {
        return state.applicationUserDetails;
    },
    getChecklistSubmissionStatus(state) {
        return state.checklistSubmissionStatus;
    },
    getOrganizationDetailStatus(state) {
        return state.organizationDetailData;
    },
    getPointOfContactStatus(state) {
        return state.pointOfContactDetaildata;
    },
    getApiAndProductStatus(state) {
        return state.apiAndProductStatus;
    },
    getCombinedFormsDetails(state) {
        return state.combinedFormsDetails;
    },
    getApplicationStatus(state) {
        return state.applicationStatus;
      },
     getOrganizationDetailData(state) {
        return state.savedOrganizationDetailData;
      },
      getPointOfContactDetailData(state) { 
        return state.savedPointOfContactDetailData;
      },
      getApiAndProductDetailData(state) { 
        return state.savedApiAndProductDetailData;
      },
      getMasterData(state) {
        return state.masterData;
      }
}

const mutations = {
    setUserData(state, userData) {
        state.userData = userData;
    },
    setNewApplicationData(state, newApplicationData) {
        state.newApplicationData = newApplicationData;
    },
    setApplicationList(state, applicationList) {
        state.applicationList = applicationList;
    },
    setCreatedApplicationData(state, createdApplicationData) {
        state.createdApplicationData = createdApplicationData;
    },
    setApplicationDetails(state, applicationDetails) {
        state.applicationDetails = applicationDetails;
    },
    setDockerFileDetails(state, dockerFileDetails) {
        state.dockerFileDetails = dockerFileDetails;
    },
    setDownloadFileURL(state, URL) {
        state.downloadFileURL = URL;
    },
    setViewUploadedResults(state) {
        return state.viewUploadedResults;
    },
    setActiveUseCase(state, activeUseCase) {
        state.activeUseCase = activeUseCase;
        return state.activeUseCase;
    },
    
    setApplicationListByFilter(state, applicationListByFilter) {
        state.applicationListByFilter = applicationListByFilter;
        return state.applicationListByFilter;
    },
    setApplicationUserDetails(state, applicationUserDetails) {
        state.applicationUserDetails = applicationUserDetails;
    },
    setShowLoadingOnApiCall(state, showLoadingOnApiCall) {
        state.showLoadingOnApiCall = showLoadingOnApiCall;
    },
    setChecklistSubmissionStatus(state, checklistSubmissionStatus) {
        state.checklistSubmissionStatus = checklistSubmissionStatus;
    },
    setSavedOrganizationDetails(state, organizationDetailData ) {
        state.organizationDetailData  = organizationDetailData ;
    }, 
    setSavedPointOfContactDetails(state, pointOfContactDetaildata ) {
        state.pointOfContactDetaildata  = pointOfContactDetaildata ;
    },
    setSavedApiAndProductDetails(state, apiAndProductStatus ) {
        state.apiAndProductStatus  = apiAndProductStatus ;
    },
    setCombinedFormsDetails(state, combinedFormsDetails) {
        state.combinedFormsDetails = combinedFormsDetails;
    },
    setApplicationStatus(state, applicationStatus) {
        state.applicationStatus = applicationStatus;
      },
    setSavedOrganizationDetailsPayload(state, organizationDetails) {
        state.savedOrganizationDetailData = organizationDetails;
      },
    setPointOfContactDetailsPayload(state, pointOfContact) {
        state.savedPointOfContactDetailData = pointOfContact;
      },
      setApiAndProductDetailsPayload(state, apiAndProduct) {
        state.savedApiAndProductDetailData = apiAndProduct;
      },
      SET_CERTIFICATION_DELETED(state, deletedData) {
        state.deletedCertification = deletedData;
      },
      setMasterData(state, masterData) {
        state.fdxVersions = masterData?.versions;
        state.fdxRoles = masterData?.roles;
        state.certificationEnvironmentRootUrl = masterData?.accountTypes;
    },
}

const actions = {

    async getOrganization({ commit, dispatch }, id) {
        if (id) {
            await api.getOrganization(id).then(response => {
                if (response?.status === 200 && response?.data) {
                    commit('setUserData', response?.data);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            })
        }
    },

    async getOrganizationFormOne({ commit, dispatch }, id) {
        if (id) {
            await api.getOrganizationFormOne(id).then(response => {
                if (response?.status === 200 && response?.data) {
                    
                    commit('setUserData', response?.data);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            })
        }
    },

    async getApplicationList({ commit, dispatch }, id) {
        commit("setShowLoadingOnApiCall", true);
        await api.getApplicationList(id).then(response => {
            if (response?.status === 200 && response?.data) {
                commit("setShowLoadingOnApiCall", false);
                commit('setApplicationList', response?.data);
                console.log("getApplicationListApi", response?.data)
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },

    async getApplicationListByFilter({ commit, dispatch}, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.getApplicationListByFilter(req).then(response => {
            if (response?.status === 200 && response?.data) {
                commit('setApplicationListByFilter', response.data);
                commit("setShowLoadingOnApiCall", false);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});        
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },

    async submitDPApplicationForm({ commit, dispatch }, request) {
        await api.submitDPApplicationForm(request).then(response => {
            if (response?.status == 200 || response?.status == 201 && response?.data) {
                commit('setCreatedApplicationData', response?.data);
                Toast.showToast('Application submitted successfully!', 'S');
                dispatch('getDockerFileDetails',request?.fdxVersions[0]);                
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }else{
                router.push("/createNewApplication");
            }            
        })
    },

    async getApplicationDetails({ commit, dispatch }, id) {
        await api.getApplicationDetails(id).then(response => {
            if (response?.status === 200 && response?.data) {
                commit('setApplicationList', response?.data);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async updateApplicationStatus({ commit, dispatch }, req) {
        await api.updateApplicationStatus(req).then(res => {
            if (res?.status === 200 && res?.data) {
                if (req?.status == 'TEST_SUITE_DOWNLOADED') {
                    console.log(req?.status);
                } else if (req?.status == 'APPROVED') {
                    Toast.showToast(
                        "Application for Certification has been approved",
                        "S"
                    );
                    router.push('/home');
                } else if (req?.status == 'REJECTED') {
                    Toast.showToast(
                        "Application for Certification has been rejected",
                        "S"
                    );
                    router.push('/home');
                }
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        });
    },


        async downloadDockerFile({commit, dispatch}, req){
            await api.downloadDockerFile(req?.imageVersion).then(res => {
                if(res?.data !== null || res?.data !== ""){
                    window.location.href = res?.data;
                    dispatch("updateApplicationStatus", req);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            });
        },


    async uploadPackage({ commit, dispatch }, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.uploadPackage(req?.upload).then(res => {
            if (res?.status == 200 && res?.data == "Success") {
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast('Test results submitted successfully', 'S');
                dispatch("getApplicationList", req?.orgId);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getApplicatiionDetailsById({ commit, dispatch }, id) {
        await api.getApplicationDetails(id).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setApplicationDetails', res?.data);
                dispatch("getOrganizationDetailsById", res?.data?.imisNumber);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getOrganizationDetailsById({ commit, dispatch }, id) {
        if (id) {
            await api.getOrganization(id).then(response => {
                if (response?.status === 200 && response?.data) {
                    commit('setApplicationUserDetails', response?.data);
                }
            }).catch(error => {
                if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                    dispatch("ssoSignout", {root:true});
                }else if(error?.response?.status == 500){
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                }
            })
        }
    },

    async getDockerFileDetails({ commit, dispatch }, version) {
        await api.getDockerFileDetails(version).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setDockerFileDetails', res?.data);
                router.push("/downloadDockerFile");
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async getViewTestResultsById({ commit, dispatch }, id) {
        await api.getViewResults(id).then(res => {
            if (res?.status == 200 || res?.status == 201 && res?.data) {
                commit('setViewUploadedResults', res?.data);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },

    async updatePaymentStatus({ commit, dispatch }, req) {
        commit("setShowLoadingOnApiCall", true);
        await api.updatePaymentStatus(req).then(res => {
            if (res?.status === 200 && res?.data) {
                commit("setShowLoadingOnApiCall", false);
                if (req?.status === 'PAYMENT_PENDING' || req?.status !== 'PAYMENT_PENDING') {
                    Toast.showToast("Fee payment status updated successfully", "S");
                }
                dispatch("getApplicationListByFilter", req?.filter);
            }
        }).catch(error => {
            if(error?.response?.status == 400 ||error?.response?.status == 401 ||error?.response?.status == 403 ||error?.response?.status == 404){
                commit("setShowLoadingOnApiCall", false);
                dispatch("ssoSignout", {root:true});
            }else if(error?.response?.status == 500){
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
            }
        })
    },


    async submitChecklist({ commit, dispatch }, checked) {
        commit("setShowLoadingOnApiCall", true);
        await api.submitChecklist(checked)
            .then(response => {
                if (response?.status === 200 || response?.status === 201) {
                    commit("setShowLoadingOnApiCall", false);
                    commit("setChecklistSubmissionStatus", response?.data);
                    Toast.showToast('Checklist submitted successfully!', 'S');
                } else {
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast('Unexpected response from server', 'E');
                }
            })
            .catch(error => {
                if (error?.response?.status === 400 || error?.response?.status === 401 || error?.response?.status === 403 || error?.response?.status === 404) {
                    commit("setShowLoadingOnApiCall", false);
                    dispatch("ssoSignout", { root: true });
                } else if (error?.response?.status === 500) {
                    commit("setShowLoadingOnApiCall", false);
                    Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
                } else {
                    router.push("/");
                }
            });
    },

    // Form 1
    async submitOrganizationDetails({ commit, dispatch }, organizationDetails) {

        commit("setShowLoadingOnApiCall", true);
        await api.submitOrganizationDetails(organizationDetails)
            .then(response => {
                if (response?.status === 200 || response?.status === 201) {
                    
                    commit("setShowLoadingOnApiCall", false);
                    commit("setSavedOrganizationDetails",response?.data);
                    commit("setSavedOrganizationDetailsPayload", organizationDetails )
                    Toast.showToast('Organization details submitted successfully!', 'S');
                } else {
                    commit("setShowLoadingOnApiCall", false);
                    commit("setSavedOrganizationDetails", "unexpected_response");
                    Toast.showToast('Unexpected response from server', 'E');
                }
            })
            .catch(error => {
                commit("setShowLoadingOnApiCall", false);
                if ([400, 401, 403, 404].includes(error?.response?.status)) {
                    dispatch("ssoSignout", { root: true });
                } else if (error?.response?.status === 500) {
                    Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
                } else {
                    router.push("/");
                }
                commit("setSavedOrganizationDetails", "error");
            });
    },

  //Form Two
  async submitPointOfContact({ commit, dispatch }, pointOfContact) {
    commit("setShowLoadingOnApiCall", true);
    await api.submitPointOfContact(pointOfContact)
        .then(response => {
            if (response?.status === 200 || response?.status === 201) {
                commit("setShowLoadingOnApiCall", false);
                commit("setSavedPointOfContactDetails", response?.data);
                commit("setPointOfContactDetailsPayload", pointOfContact )
                Toast.showToast('Point of Contact details submitted successfully!', 'S');
            } else {
                commit("setShowLoadingOnApiCall", false);
                commit("setSavedPointOfContactDetails", "unexpected_response");
                Toast.showToast('Unexpected response from server', 'E');
            }
        })
        .catch(error => {
            commit("setShowLoadingOnApiCall", false);
            if ([400, 401, 403, 404].includes(error?.response?.status)) {
                dispatch("ssoSignout", { root: true });
            } else if (error?.response?.status === 500) {
                Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
            } else {
                router.push("/");
            }
            commit("setSavedPointOfContactDetails", "error");
        });
},

// Form Three
async submitApiAndProduct({ commit, dispatch }, apiAndProduct) {
    commit("setShowLoadingOnApiCall", true);
    await api.submitApiAndProduct(apiAndProduct)
        .then(response => {
            if (response?.status === 200 || response?.status === 201) {
                commit("setShowLoadingOnApiCall", false);
                commit("setSavedApiAndProductDetails", response?.data);
                commit("setApiAndProductDetailsPayload", apiAndProduct )
                Toast.showToast('Point of Contact details submitted successfully!', 'S');
            } else {
                commit("setShowLoadingOnApiCall", false);
                commit("setSavedApiAndProductDetails", "unexpected_response");
                Toast.showToast('Unexpected response from server', 'E');
            }
        })
        .catch(error => {
            commit("setShowLoadingOnApiCall", false);
            if ([400, 401, 403, 404].includes(error?.response?.status)) {
                dispatch("ssoSignout", { root: true });
            } else if (error?.response?.status === 500) {
                Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
            }
            commit("setSavedApiAndProductDetails", "error");
        });
},
 //Summary Get Data
async submitCombinedFormsDetails({ commit, dispatch }, { form1Id, form2Id, form3Id, consentId , imisNumber}) {
    commit("setShowLoadingOnApiCall", true);
    await api.submitCombinedFormsDetails(form1Id, form2Id, form3Id, consentId, imisNumber).then(response => { 
     
        if (response?.status === 200 && response?.data) {
            commit('setCombinedFormsDetails', response.data);
            commit("setShowLoadingOnApiCall", false);
        }
    }).catch(error => {
        commit("setShowLoadingOnApiCall", false);
        if (error?.response?.status == 400 || error?.response?.status == 401 || error?.response?.status == 403 || error?.response?.status == 404) {
            dispatch("ssoSignout", { root: true });
        } else if (error?.response?.status == 500) {
            Toast.showToast(error?.response?.data?.message ? error?.response?.data?.message : 'Internal server error', "E");
        }
    });
},
  
// Submit Application
async submitApplicationDetails({ commit, dispatch },paramPayload) {
    const { param, result,certAppUrl } = paramPayload;
    commit("setShowLoadingOnApiCall", true);
    await api.submitApplicationDetails(param.form1Id, param.form2Id, param.form3Id, result ).then(response => {
      
        if (response?.status === 200 || response?.status === 201) {
          commit("setShowLoadingOnApiCall", false);
          commit("setApplicationStatus", response?.data);
          commit("setSavedOrganizationDetailsPayload", null);
          commit("setPointOfContactDetailsPayload", null);
          commit("setApiAndProductDetailsPayload", null);
          commit("setSavedOrganizationDetails", null);
          commit("setSavedApiAndProductDetails", null);
          commit("setSavedPointOfContactDetails", null);  
          Toast.showToast('Application details submitted successfully!', 'S');
          const newUrl = `${certAppUrl}?id=${response?.data?.id}`
          window.location.href=newUrl;
        } else {
          commit("setShowLoadingOnApiCall", false);
          commit("setApplicationStatus", "unexpected_response");
          Toast.showToast('Unexpected response from server', 'E');
          
        }
      })
      .catch(error => {
        commit("setShowLoadingOnApiCall", false);
   
        if ([400, 401, 403, 404].includes(error?.response?.status)) {
          dispatch("ssoSignout", { root: true });
        } else if (error?.response?.status === 500) {
          Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
        } else {
          router.push("/");
        }
        commit("setApplicationStatus", "error");
      });
  },

  // Update Form one Api
async updateOrganizationDetails({ commit, dispatch }, payload) {
    commit("setShowLoadingOnApiCall", true);
    try {
      const response = await api.updateOrganizationDetails(payload?.id, payload?.organizationDetails);
      if (response?.status === 200 || response?.status === 201) {
        commit("setShowLoadingOnApiCall", false);
        commit("setSavedOrganizationDetails", response?.data);
        commit("setSavedOrganizationDetailsPayload",  payload?.organizationDetails)
        Toast.showToast('Organization details updated successfully!', 'S');
      } else {
        commit("setShowLoadingOnApiCall", false);
        commit("setSavedOrganizationDetails", "unexpected_response");
        Toast.showToast('Unexpected response from server', 'E');
      }
    } catch (error) {
      commit("setShowLoadingOnApiCall", false);
      if ([400, 401, 403, 404].includes(error?.response?.status)) {
        dispatch("ssoSignout", { root: true });
      } else if (error?.response?.status === 500) {
        Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
      } else {
        router.push("/");
      }
      commit("setSavedOrganizationDetails", "error");
    }
  },  
  
  // Update Form Two Api
  async updatePointOfContactDetails({ commit, dispatch },  payload ) {
    commit("setShowLoadingOnApiCall", true);
    try {
        const response = await api.updatePointOfContactDetails(payload.id, payload.pointOfContact);
        if (response?.status === 200 || response?.status === 201) {
            commit("setShowLoadingOnApiCall", false);
            commit("setSavedPointOfContactDetails", response?.data);
            commit("setPointOfContactDetailsPayload", payload.pointOfContact )
            Toast.showToast('Point of Contact details updated successfully!', 'S');
        } else {
            commit("setShowLoadingOnApiCall", false);
            commit("setSavedPointOfContactDetails", "unexpected_response");
            Toast.showToast('Unexpected response from server', 'E');
        }
    } catch (error) {
        commit("setShowLoadingOnApiCall", false);
        if ([400, 401, 403, 404].includes(error?.response?.status)) {
            dispatch("ssoSignout", { root: true });
        } else if (error?.response?.status === 500) {
            Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
        } else {
            router.push("/");
        }
        commit("setSavedPointOfContactDetails", "error");
    }
},

 // Update Form Three Api
 async updateApiAndProductionDetails({ commit, dispatch },  payload ) {
    commit("setShowLoadingOnApiCall", true);
    try {
        const response = await api.updateApiAndProductionDetails(payload.id, payload.apiAndProduct);
        if (response?.status === 200 || response?.status === 201) {
            commit("setShowLoadingOnApiCall", false);
            commit("setSavedApiAndProductDetails", response?.data);
            commit("setApiAndProductDetailsPayload", payload.apiAndProduct )
            Toast.showToast('API And Product details updated successfully!', 'S');
        } else {
            commit("setShowLoadingOnApiCall", false);
            commit("setSavedApiAndProductDetails", "unexpected_response");
            Toast.showToast('Unexpected response from server', 'E');
        }
    } catch (error) {
        commit("setShowLoadingOnApiCall", false);
        if ([400, 401, 403, 404].includes(error?.response?.status)) {
            dispatch("ssoSignout", { root: true });
        } else if (error?.response?.status === 500) {
            Toast.showToast(error?.response?.data?.message || 'Internal server error', "E");
        } else {
            router.push("/");
        }
        commit("setSavedApiAndProductDetails", "error");
    }
},
async softDeleteCertification({ commit }, payload) {
    try {
      const response = await api.softDeleteCertification(payload.form1Id, payload.form2Id, payload.form3Id);
      commit('SET_CERTIFICATION_DELETED', response.data);
      commit("setSavedOrganizationDetailsPayload", null);
      commit("setSavedOrganizationDetails", null);
      commit("setPointOfContactDetailsPayload", null);  
      commit("setSavedPointOfContactDetails", null);
      commit("setSavedApiAndProductDetails", null);
      commit("setApiAndProductDetailsPayload", null);
      
      return response.data;
    } catch (error) {
      console.error('Error soft deleting certification:', error);
      throw error;  // Optionally throw the error to handle it in the component
    }
  },
  async getMasterDataAction({ commit}, ){
    try {
      commit('setShowLoadingOnApiCall', true)
      const response = await api.getMasterdatadetails();
      if (response && response.status === 200) {
        commit('setMasterData', response.data);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      commit('setShowLoadingOnApiCall', false);
    }
  },

}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}