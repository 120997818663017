import axios from 'axios';
import Store from "../store";

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.


const AxiosInterceptors = axios.create({

    // baseURL: `https://example.com/api`,
    //headers: { 'Authorization': "Bearer " + access_token }
});



const isTokenExperied = () => {

    if (Store.getters.getRefreshTokenStartTime) {
        const diff = new Date().getTime() - Store.getters.getRefreshTokenStartTime;
        return diff > 600000;
    } else {
        return false
    }

}
const refreshToken = () => {
    if ( Store.getters.getToken) {
        Store.dispatch('refreshToken');
    }
}
// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.

AxiosInterceptors.interceptors.request.use(
    async config => {
        let token = Store.getters.getToken;
        if (config.url.includes('refreshtoken')) {
            const refresh_token = JSON.parse(token).refresh_token;
            config.url = config.url + refresh_token;
            return config;
        } else {
            if (token && isTokenExperied() === true) {
                refreshToken();
            }
            token = Store.getters.getToken;
            if (Store && token) {
                const access_token = JSON.parse(token).access_token;
                config.headers.Authorization = "Bearer " + access_token;
                return config;
            } else {
                return config;
            }
        }
    },
    error => {
        return  Promise.reject(new Error(error))
    });

// Response interceptor for API calls
AxiosInterceptors.interceptors.response.use((response) => {
    return response
}, async function (error) {

    return Promise.reject(new Error(error));
});
// Step-4: Export the newly created Axios instance to be used in different locations.
export default AxiosInterceptors;